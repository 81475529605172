<template>
  <div class="other--facility-field" :class="{ 'has-error': validation.hasError('otherFacility') }">
    <span class="left--text">{{ index + 1 }}. </span>
    <input
      name="other_facilities[]"
      :value="otherFacility"
      ref="otherFacilityField"
      @input="inputOtherFacility"
      :placeholder="$t('addListing.placeholder.otherFacility')"
      class="basic--input"
      type="text"
    />
    <img
      src="@/assets/img/icons/trash.svg"
      v-show="length > 1"
      @click="removeOtherFacility"
      class="trash--button"
    />
    <span class="val-error" v-if="validation.hasError('otherFacility')">{{
      validation.firstError('otherFacility')
    }}</span>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

export default {
  name: 'other-facility',
  props: ['otherFacility', 'length', 'index'],
  validators: {
    otherFacility: {
      validator(value) {
        return Validator.value(value).maxLength(
          100,
          this.$i18n.t('errors.addListing.otherFacility.max', { maxChar: 100 }),
        );
      },
    },
  },
  methods: {
    inputOtherFacility($event) {
      this.$store.commit('v2/listingForm/sect2/detail/INPUT_OTHER_FACILITY', {
        input: $event.target.value,
        index: this.index,
      });
    },
    removeOtherFacility() {
      this.$store.commit('v2/listingForm/sect2/detail/REMOVE_OTHER_FACILITY', this.index);
    },
    validate: function() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style scoped></style>
